<template>
  <b-row align-v="center" align-h="center" class="h-100">
    <Intro />
  </b-row>
</template>

<script>
import Intro from '@/components/Intro.vue'

export default {
  components: {
    Intro
  }
}
</script>
